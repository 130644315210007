import { MapContainer, Marker, Polyline, TileLayer } from "react-leaflet";
import L from "leaflet";
import markerIcon from "../../assets/marker.svg";
import { ITrackingMapProps } from "../../interfaces";

const CUSTOM_MARKER = L.icon({
  iconUrl: markerIcon,
  iconSize: [38, 38],
  iconAnchor: [19, 38],
  popupAnchor: [0, -38],
});

const TrackingMap = ({ from, to }: ITrackingMapProps): JSX.Element => {
  return (
    <>
      {from && to && (
        <MapContainer
          style={{ width: "100%", height: "100vh" }}
          zoom={3}
          center={{ lat: from.lat, lng: from.lng }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

          <Marker position={from} icon={CUSTOM_MARKER} />
          <Marker position={to} icon={CUSTOM_MARKER} />
          <Polyline positions={[from, to]} pathOptions={{ color: "red" }} />
        </MapContainer>
      )}
    </>
  );
};
export default TrackingMap;
