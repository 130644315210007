import { BrowserRouter, Routes, Route } from "react-router-dom";
import { TrackRequest, TrackShipment } from "./pages";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/track-shipment/:id/:apiKey" element={<TrackShipment />} />
        <Route path="/track-request/:id/:apiKey" element={<TrackRequest />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
