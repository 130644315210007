import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IMarker } from "../../interfaces";
import { TrackingMap } from "../../component";
import { API_URL } from "../../consts";
import axios from "axios";
import { log } from "console";

const TrackShipment = (): JSX.Element => {
  const { id, apiKey } = useParams();
  const [from, setFrom] = useState<IMarker | undefined>(undefined);
  const [to, setTo] = useState<IMarker | undefined>(undefined);

  useEffect(() => {
    if (id && apiKey) {
      handleFetchDataAPI();
    }
  }, [id, apiKey]);

  console.log(apiKey);
  

  const handleFetchDataAPI = useCallback(async () => {
    try {
      const response = await axios.get(`${API_URL}/shipment/from-to?id=${id}`, {
        headers: { Authorization: `Bearer ${apiKey}` },
      });
      const { from, to } = response.data;
      setFrom({
        lat: parseFloat(from.latitude),
        lng: parseFloat(from.longitude),
      });
      setTo({ lat: parseFloat(to.latitude), lng: parseFloat(to.longitude) });
    } catch (err) {
      console.error(err);
    }
  }, [id, apiKey]);

  return <TrackingMap from={from} to={to} />;
};
export default TrackShipment;
